<template>
    <div>
        <!--AxPUPurchaseOrderBDetail.vue-->
        <el-container>
            <el-main style="height: 600px;">
                <el-row>
                    <el-col :span="20">
                        <el-button-group>
                            <el-button type="" size="mini" @click="sysdetailadd">新增</el-button>
                            <el-button type="" size="mini" @click="sysdetailupdate">修改</el-button>
                            <el-button type="" size="mini" @click="sysdetailsave">保存</el-button>
                        </el-button-group>
                    </el-col>
                </el-row>
                <vxe-form title-align="right" title-width="140">
                    <vxe-form-gather span="24" :visible="false" >
                        <vxe-form-item title="guid" field="guid" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].guid" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].guid" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="pguid" field="pguid" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].pguid" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].pguid" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="存货编号" field="inventorycode" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].inventorycode" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].inventorycode" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="项目信息" field="projectcode" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].projectcode" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].projectcode" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="数量" field="Quantity" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].Quantity" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].Quantity" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="单位" field="unitcode" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].unitcode" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].unitcode" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                    <vxe-form-gather span="24"  >
                        <vxe-form-item title="price" field="price" :item-render="{}" span="6">
                            <template #default>
                                <vxe-input v-if="content.pagestatus !='view'" v-model="DataSet.tables[mastertable].rowsview[0].price" placeholder="" size="mini" clearable></vxe-input>
                                <vxe-input v-else disabled v-model="DataSet.tables[mastertable].rowsview[0].price" placeholder="" size="mini" clearable></vxe-input>
                            </template>
                        </vxe-form-item>
                    </vxe-form-gather>
                </vxe-form>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";
    export default {
        name: "ax_PU_PurchaseOrder_bdetail",
        extends: ax.control1(),
        data() {
            return {
                vouchertype: "detail",
                mastertable: "ax_PU_PurchaseOrder_b",
                model: "zb",
                settings: "axPUPurchaseOrderB",
                datainit: {
                    "ax_PU_PurchaseOrder_b": {
                        "keyname": "ax_PU_PurchaseOrder_b",
                        "key": "guid",
                        "ptable": "",
                        "pkey": "",
                        "relation": "",
                        "datatype": "datafree",
                        "dataname": "主表",
                        "jsexps": [],
                        "hasseq": true,
                        "rows": [{
                               guid:"",
                               pguid:"",
                               inventorycode:"",
                               projectcode:"",
                               Quantity:0 ,
                               unitcode:"",
                               price:0 ,
                        }]
                    }
                }
            }
        },
    }
</script>